// Modyfikacje dla okna Bootstrap

.modal-header.with-action {
  display: flex;
  justify-content: space-between;
  align-items: center;

  * {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@include media-breakpoint-up("md") {
  .modal-content {
    max-height: calc(100vh - 4rem);
  }
}

.modal-body {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.modal-header {
  padding-bottom: 0;
  button.close {
    outline: 0;
    color: $body-color;
    margin-top: 0.5rem;
    padding: 0;
    font-size: 4rem;
    transform: rotate(0deg);
    transition: .3s;
    opacity: 1;

    &:hover {
      transform: rotate(3105deg);
    }
  }

  .modal-title.h4 {
    font-size: $h1-font-size;
  }
}


.modal-footer {
  padding-top: 0;
}

.modal-dialog-scrollable {
  .modal-body {
    padding-bottom: 1rem;
  }
  .modal-footer {
    padding-top: 1rem;
  }
}

.register-vindication {
  .modal-footer {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $blackSqueezeLight;
  }
}

.modal-backdrop-error {
  opacity: 0.9 !important;
  z-index: 2040;
}

.modal-error {
  z-index: 2050;
}