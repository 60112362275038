div.circle-progress {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  animation: progress 2s 1 forwards;

  > * {
    text-align: center;
  }
}