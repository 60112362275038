// Style dla administracyjnej części aplikacji
$mode-admin: true;
$mode-client: false;
$mode: "admin";

// Konfiguracja dla Bootstrapa
// Importujemy wspólną konfigurację dla Bootstrapa
@import "common_variables";
$secondary: $botticelli;

@import "bootstrap/functions";
@import "bootstrap/variables";
// Kolory dla własnego rozszerzenia - kolor aktywnego obiektu
$theme-colors-active: (
        "primary":    $primary-active,
        "secondary":  darken($secondary, 10%),
        "success":    darken($success, 10%),
        "info":       darken($info, 10%),
        "warning":    darken($warning, 10%),
        "danger":     darken($danger, 10%),
        "light":      darken($light, 10%),
        "dark":       darken($dark, 10%)
);

$custom-control-indicator-checked-border-color: $custom-control-indicator-border-color;
$custom-control-indicator-active-border-color: $custom-control-indicator-border-color;

@import "bootstrap";

// Tu dodatkowe niezwiązane z Bootstrapem
$hamburger-layer-color: #fff;
@import "hamburger";
@import "components/webfonts";
@import "components/utils";

// Ustawienia własne dla szablonu
$menu-background: #a7bdd3;
$menu-background-hover: $secondary;
$menu-color: $body-color;
$menu-color-hover: $body-color;
$menu-logo-background: #c1d1e0;

$top-menu-background: $body-color;

$main-background: #dce5ed;

@import "components/icons";
@import "components/layout";
@import "components/menu";
@import "components/modal";
@import "components/inputs";
@import "components/react-select";
@import "components/other";
@import "components/login";
@import "components/tables";
@import "components/navs";
@import "components/datepicker";
@import "components/columns";
@import "components/rights";
@import "components/dropzone";
@import "components/filter";
@import "components/status";
@import "components/progressbar";
@import "components/card";
@import "components/alert";
@import "components/fonts";
@import "components/autosuggest";
@import "components/progress-button";
@import "components/react-image-lightbox";
@import "components/captcha";
@import "components/cursor";
@import "components/im-preview";
@import "components/circle-progress";
@import "components/hint";

@import "fragments/top_search";
@import "components/quill-view";

// Ikony do menu klienckiego i administracyjnego są przygotowane na dwa różne sposoby stąd definicja poniżej
#main-navigation .accordion {
  > button, >a {
    .rf-icon {
      opacity: 0.4;
    }
    &:hover, &.expanded {
      background-color: $menu-background-hover;
      span {
        opacity: 1;
      }
    }
  }
}

// Na potrzeby edycji edytora powiadomień/e-maili
@import "components/quill";
$content-width: auto;
$email-res-path: "";
@import "email_defs";
div.email-editor {
  max-width: 600px;

  @include email-template(".ql-editor");

  .ql-editor {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;

    blockquote {
      border: none;
    }

  }
}

@import "im_defs";

div.im-editor {
  max-width: 600px;

  @include im_template(".ql-editor");

  .ql-editor {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;

    blockquote {
      border: none;
    }

  }

}

#top-navigation {
  color: white;
  padding-top: 1em;
  padding-bottom: 1em;
  align-items: center;

  form {
    button {
      font-weight: normal;
      color: $top-menu-background;
    }
  }

  .user {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
  }

  button {
    color: white;
    font-size: $font-size-base;
    font-weight: 600;

    &:hover {
      color: $dodgerBlue;
    }

    span {
      color: $secondary;
      margin-right: 1em;
    }
  }
}

#user-tabs-tabpane-agreements {
  .row {
    border-bottom: 1px solid $secondary;
    margin-bottom: 1rem;
  }
}
