// Style dla komponentu wyszukiwania na górnej belce (panel admina).

#top-navigation form.admin-search {
  #type-select {
    min-width: 11em;
  }

  div.search-results {
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(3,66,128,.1);
    min-width: 40em;
    z-index: 10;

    > * {
      display: block;
      padding: 0.5em;
      margin: 0;

      &:hover {
        background-color: $secondary;
      }

      div.search-client {
        font-size: $font-size-sm;
        font-weight: $font-weight-normal;
        color: $dodgerBlue;

        span.tax {
        }
      }
      div.result-label {
        span.type {
          font-weight: $font-weight-normal;
          &:after { content: ": " }
        }
        span.id {
          &:after { content: ", " }
        }
      }
    }
  }
}
