// Definicje kolumn dla tabel z danymi

@mixin column-width($name, $width) {
  td.#{$name}, th.#{$name} {
    max-width: $width;
    min-width: $width;
    width: $width;
  }
}

@mixin column-min-width($name, $width) {
  td.#{$name}, th.#{$name} {
    min-width: $width;
  }
}

// Ogólne definicje kolumn bez typowania dla konkretnej tabeli
table {
  @include column-width("row-select", 4em);

  @include column-width("id", 7em);
  @include column-width("days", 9em);
  @include column-width("email", 20em);
  @include column-width("datetime", 12em);
  @include column-width("date", 8em);
  @include column-width("date-ago", 10em);  // data z liczbą dni temu
  @include column-width("status", 12em);
  @include column-width("nip", 12em);
  @include column-width("money", 11em);
  @include column-width("money-big", 14em);
  @include column-width("type", 27em);
  @include column-width("stage", 15em);
  @include column-width("icon", 8em); // ikonka ze statusem, ale 8 jest na nagłówek

  @include column-width("checkbox", 13em);

  @include column-width("delete", 4.5em);
  @include column-width("actions", 10em);
  @include column-width("actions2", 18em);
  @include column-width("actions3", 26em);

  @include column-min-width("mw-sm", 6em);
  @include column-min-width("mw-md", 12em);
  @include column-min-width("mw-ld", 20em);
  @include column-min-width("mw-xl", 30em);

  @include column-width("sm", 6em);
  @include column-width("smd", 8em);
  @include column-width("mmd", 10em);
  @include column-width("md", 12em);
  @include column-width("mnd", 13.5em);
  @include column-width("nd", 15em);
  @include column-width("lg", 20em);
  @include column-width("xl", 30em);

  @include column-width("half", 50%);
  @include column-width("third", 33.333%);
  @include column-width("lang-part", 50%);

  @include column-width("input-date", 9em);
  @include column-width("input-money", 12em);
  @include column-width("input-type", 12em);

  td.hard-break {
    overflow-wrap: break-word;
  }

  td.money-big, td.money {
    text-align: right;
  }

  td.col-center {
    text-align: center;
  }

  td.col-right {
    text-align: right;
  }
}

.datatable-users {

}

td {
  h1.number {
    display: block;
    text-align: right;
  }
}

tr.disconnected {
  background: #eee;
}