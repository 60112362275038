// Definicje stylów dla e-maila, które można wrzucić w innych miejscach - @include

@mixin email-template($main-name) {
  a {
    color: $secondary;
    text-decoration: underline;
    font-weight: $font-weight-bold;
    word-wrap: break-word;
  }

  // Część główna
  #{$main-name} {
    background: white;
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 14px;
    line-height: 1.4;

    em {
      font-style: normal;
      color: $dodgerBlue;
    }

    p, h1, h2, h3 {
      margin: 0 0;
      padding: 0 30px;
    }

    h1 {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.4;
      padding-bottom: 30px;
    }

    h2 {
      font-size: 26px;
      font-weight: 700;
      line-height: 1.4;
      padding-bottom: 30px;
    }

    h3 {
      font-size: 20px;
    }

    // Tak jest na potrzeby edytora Quill, aby nie komplikować sobie życia, tylko użyć istniejący tam styl
    blockquote {
      border: none !important;
      margin: 0 0;
      padding: 20px 30px;
      background: $blackSqueezeLight;

      + p {
        padding-top: 20px;
      }

      + blockquote {
        padding-top: 0;
      }

    }

    > p:last-child {
      padding-bottom: 20px;
    }

    a.email-btn {
      font-weight: 700;
      text-decoration: none;
      color: #fff;
      background-color: #FF8900;
      font-size: 16px;
      padding: 12px 30px;
      border-radius: 20px;
      text-transform: uppercase;
      display: inline-block;
      margin-bottom: 12px;
    }
  }
}