

.tab-content {
  background-color: $white;

  margin-left: -$content-padding;
  margin-right: -$content-padding;

  padding-left: $content-padding;
  padding-right: $content-padding;

  padding-top: 4rem;
  padding-bottom: 4rem;

  @include small-screen-down {
    margin-left: -$content-padding-sm;
    margin-right: -$content-padding-sm;

    padding-left: $content-padding-sm;
    padding-right: $content-padding-sm;
  }
}

.nav.nav-tabs {
  .nav-item {
    font-size: $h4-font-size;

    a {
      color: $regentStBlue;
      font-weight: 600;
      &:hover {
        background-color: $nav-tabs-link-active-bg;
        color: $dodgerBlue;
      }
    }
  }

  a.nav-link.active:hover, a.nav-link.active {
    color: $nav-tabs-link-active-color;
  }

  .nav-item:not(:first-child) {
    margin-left: 1.5rem;
  }

  .actions {
    flex-grow: 1;
    text-align: right;
    align-self: center;
  }
}