// Style dla różnych drobnych rzeczy, które nie można podpiąć do innej grupy


.in-circle {
  display: flex;
  align-items: center;
  border: 1px solid;
  border-radius: 50%;
  width: 2em;
  height: 2em;

  > * {
    margin-left: auto;
    margin-right: auto;
  }

  &.big {
    width: 4em;
    height: 4em;
  }
}

nav[aria-label=breadcrumb] {
  border-bottom: 1px solid $breadcrumb-separator-color;
  //margin-bottom: 2rem;

  .breadcrumb-item:before {
    width: 5px;
    height: 1.5em;
    background: url(/resources/img/breadcrumb-arrow.png) no-repeat 50%;
    margin-right: .5rem;
    padding-right: 0;
  }
}

.page-header-spacing {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.page-header {
  padding-bottom: 2rem;
  padding-top: 2rem;
  display: flex;
  align-items: center;

  h1 {
    flex-grow: 1;
    padding: 0;
    margin: 0;
  }
  > *:not(:first-child) {
    margin-left: 1em;
  }
}

.subheader {
  padding-bottom: 2rem;
  padding-top: 2rem;
  display: flex;
  align-items: center;

  h1, h2, h3 {
    flex-grow: 1;
    padding: 0;
    margin: 0;
  }

  > *:not(:first-child) {
    margin-left: 1em;
  }
}

.badge.type-badge, .badge.risk-badge {
  text-transform: uppercase;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
}

span.days-ago {
  font-size: $font-size-sm;
}

div.date, span.date {
  white-space: nowrap;
}