.font-big {
  font-size: $font-size-lg;
}

.font-h1 {
  font-size: $h1-font-size;
}

.font-h2 {
  font-size: $h2-font-size;
}

.font-h3 {
  font-size: $h3-font-size;
}
.font-h4 {
  font-size: $h4-font-size;
}