// Plik ze stylami dla obszaru drag&dropowania plików obsługiwanego przez Dropzone.

div.dropzone-border {
  border: 1px dashed $secondary;
  &.active {
    border-color: $primary;
  }
}

div.dropzone {
  padding: 1.5rem;
  text-align: center;
  border: 1px dashed $secondary;
  border-radius: 10px;
  background-color: white;
  outline: none;

  &.dropzone-danger {
    border-color: $danger;
  }

  &.active {
    border-color: $primary !important;
  }



  button {

  }

  p {
    padding-top: 1rem;
  }

  input + p {
    padding-top: 0;
  }

  .preview {
    height: 128px;
    margin-bottom: 10px;

    img {
      object-fit: contain;
      max-width: 256px;
      max-height: 128px;
    }
  }

  .file-actions {
    text-align: center;
    padding-bottom: 0.5rem;
    > *:not(:first-child) {
      margin-left: 5em;
    }
  }

  .file-info {
    padding: 0.5rem 0;
    display: flex;
    span:first-child {
      text-align: left;
      flex-grow: 1;
      word-break: break-all;
    }
    span:not(:first-child) {
      margin-left: 1em;
      text-align: right;
    }
  }
}

div.files {
  div.form-row {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .preview {
    text-align: center;
    width: 1em;
    max-width: 1em;
    font-size: 5rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }
}