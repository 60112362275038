// Plik ze stylami związanymi z komponentem filtra

// Przycisk szablonu filtra
.filter-template {
  > button.btn {
    background-color: white !important;
    color: $body-color !important;
    text-align: left;
    border: 0;

    text-transform: none;
    font-size: $font-size-base !important;

    border-radius: 15px;
  }

  .dropdown-menu {
    box-shadow: 1px 14px 24px 0 rgba(12,62,116,.1);

    .dropdown-item {
      display: flex;
      span:first-child {
        flex-grow: 1;
      }

      span.delete {
        visibility: hidden;
        color: red;
        padding-left: 0.5em;
        padding-right: 0.5em;

        &:hover {
          background-color: lightgray;
        }
      }
      &:hover {
        span.delete {
          visibility: visible;
        }
      }
    }
  }
}

// Właściwy komponent filtra
.filter {
  $height: 3.5rem;
  $color: $steelBlue;
  $color-value: $congressBlue;
  $color-hover: $linkWater;
  $border-color: $steelBlue;
  $border-radius: $input-border-radius;

  > * {
    margin-top: 1em;
    &:not(:last-child) {
      margin-right: 1em;
    }
  }

  .form-group.filter-main input {
    height: $height;
  }

  .btn {
    color: $color !important;
    border-color: $color !important;
    border-width: 1px;
    border-radius: $border-radius;

    &:hover, &:active {
      background-color: $white !important;
    }
  }

  .dropdown {
    height: $height;
    display: flex;

    &.show button:after {
      transform: rotate(180deg);
    }

    button.dropdown-toggle {
      color: $color;
      background-color: $white;
    }

    // Dropdown opcji
    &.filter-item {



      // Przycisk opcji
      button.dropdown-toggle {
        display: flex;
        align-items: center;
        padding-right: 1em;
        padding-left: 1em;


        div {
          flex-grow: 1;
        }

        span.remove {
          font-size: 0.7em;
          color: transparent;
          outline: none;
          background-color: transparent;
          border: 0;
          position: absolute;
          right: 4px;
          top: 3px;
        }
        &:hover span.remove{
          color: $red;
        }

        div {
          min-width: 5em;
          label {
            display: block;
            font-size: $font-size-base*0.8;
          }
          span {
            display: block;
            color: $color-value;
            text-transform: none;
          }

          &.no-value label {
            font-size: $font-size-base;
          }
        }
      }

      // Formularz opcji
      .dropdown-menu {
        div.form {
          padding: 0 1em;

          div.form-group {
            padding: 0.2em 0;
          }
        }
      }
    }

    // Dropdown dodawania pola
    &.filter-add {
      button.dropdown-toggle {
        border-style: dashed;
      }

      .dropdown-item {
        font-weight: $font-weight-bold;
        color: $color-value;

        &:hover, &:active {
          background-color: $color-hover;
        }
      }
    }
  }

  .filter-item.readonly {

    .input-group-text {
      padding: $btn-padding-y $btn-padding-x;
      background-color: #eee;
    }

    div.readonly-content {
      text-transform: uppercase;
      font-weight: $btn-font-weight;
      min-height: 1.5em;
      text-align: left;

      label {
        display: block;
        color: $color;
        font-size: $font-size-base*0.8;
      }

      &.no-value label {
        font-size: $font-size-base;
      }

      span {
        display: block;
        color: $color-value;
        text-transform: none;
        font-size: 1rem;
      }
    }
  }


  .main-search input {
    width: 20em;
  }

  .filter-item {
    .status {
      min-width: 10em;
    }

    .dropdown-menu {
      min-width: 12em;
    }
  }
}