.clickable {
    cursor: pointer;
}

.big-icon {
    font-size: 2em;
}

.glass-pane {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    z-index: 1105;
}

.progress {
    position: relative;
}
.progress .progress-bar {
    z-index: 1;
    /*padding-left: 4px;*/
}

.progress .progress-bar-text {
    position: absolute;
    text-align: center;
    left: 0; top: -1px;
    padding-left: 4px;
    z-index: 0;
}

.menu-more:AFTER {
    display: inline-block;
    position: absolute;
    right: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "\25BE";
}

.clickable {
    cursor: pointer;
}

.react-datepicker-wrapper {
    display: block !important;
}

img.uploader-preview {
    max-width: 5em;
    max-height: 5em;
}

//.suggestion-content {
//    display: flex;
//    align-items: center;
//    background-repeat: no-repeat;
//}
//
//.name {
//    margin-left: 68px;
//    line-height: 45px;
//}
//

.dropdown-menu-pos {
    margin-top: -0.9em;
    margin-left: 0.3em;
}

.clear-list {
    list-style-type:none;
    margin:0;
    padding: 0;
    li {
        text-indent: 0;
    }
}

img.flag {
    max-width: 2em;
    max-height: 2em;
    width: 2em;
    object-fit: contain;
}

.highlight {
    color: #ee0000;
    font-weight: bold;
}
//
//.react-autosuggest__suggestion--focused .highlight {
//    color: #120000;
//}


.center {
    /* horizontally and vertically center all content */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.file-drop {
    /* relatively position the container bc the contents are absolute */
    position: relative;
    min-height: 100px;
    width: 100%;

    > .file-drop-target {
        width: 100%;
        height: 100%;
        border-radius: 2px;

        &.file-drop-dragging-over-frame {
            /* overlay a black mask when dragging over the frame */
            border: none;
            background-color: rgba(0, 0, 0, 0.65);
            box-shadow: none;
            z-index: 50;
            opacity: 1;

            /* typography */
            color: white;
        }

        &.file-drop-dragging-over-target {
            /* turn stuff orange when we are dragging over the target */
            color: #ff6e40;
            box-shadow: 0 0 13px 3px #ff6e40;
        }
    }
}

.small-flag {
    width: auto;
    height: 1em;
    border: 1px solid #ddd;
}