// wspólne style dla aplikacji

// Pomocniczy mixin dla małego rozmiaru ekranu, aby wszędzie było to samo
@mixin small-screen-down {
  @media (max-width: 1129.9px) {
    @content;
  }
}

@mixin small-screen-up {
  @media (min-width: 1130px) {
    @content;
  }
}

@include small-screen-down {
  .full-hide { }
  .small-hide {
    display: none !important;
  }
}

@include small-screen-up {
  .full-hide {
    display: none !important;
  }
  .small-hide { }
}


.overflow-y {
  overflow-y: auto;
}

.overflow-x {
  overflow-x: auto;
}

.no-border {
  border: 0;
}

.vr {
  width: 1px;
  height: calc(100% - 10px);
  background-color: rgba(3,66,128,.15);
  margin: 5px 5px 10px 5px;

}

@mixin icomoon($size: 22px, $icon: "\e902") {
  font-family: 'icomoon' !important;
  font-size: $size;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: $icon;
}

.mh-2 { min-height: 2rem; }
.mh-3 { min-height: 3rem; }
.mh-5 { min-height: 5rem; }
.mh-10 { min-height: 10rem; }

.mt--3rem { margin-top: -3rem; }

.min-h-100 {
  min-height: 100%;
}

.min-w-100 {
  min-width: 100%;
}

.max-width-xs {
  max-width: 400px;
}

@each $name, $size in $container-max-widths {
  .max-width-#{$name} {
    max-width: $size;
  }
}

.font-size-lg {
  font-size: $font-size-lg;
}

.font-size-sm {
  font-size: $font-size-sm;
}

.solid {
  white-space: nowrap;
}

div.left-right {
  display: flex;

  //> * {
  //  display: inline-block;
  //}

  > *:first-child {
    flex-grow: 1;
  }
  > *:not(:first-child) {
    text-align: right;
  }
}

.c-processing {
  cursor: wait !important;
}