@mixin im-template($main-name) {
  #{$main-name} {
    a {
      color: $secondary;
      text-decoration: underline;
      font-weight: $font-weight-bold;
      word-wrap: break-word;
    }

    em {
      font-style: normal;
      color: $dodgerBlue;
    }

    p, h1, h2, h3 {
      margin: 0 0;
      padding: 0 30px;
    }

    table {
      border-spacing:0;
      border-collapse: collapse;

      td, th {
        border: 1px solid black;
      }
    }

    h1 {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.4;
      padding-bottom: 30px;
    }

    h2 {
      font-size: 26px;
      font-weight: 700;
      line-height: 1.4;
      padding-bottom: 30px;
    }

    h3 {
      font-size: 20px;
    }
  }
}