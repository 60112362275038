// Style dla komponentu React-Select

.react-select {
  position: relative;
  box-sizing: border-box;

  .react-select__control {
    align-items: center;
    background-color: white;
    border: $input-border-width solid $input-border-color;
    @include border-radius($input-border-radius, 0);
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    //min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;

    .react-select__value-container {
      align-items: center;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      padding: 2px 8px;
      -webkit-overflow-scrolling: touch;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      min-width: 10em;

      &--has-value {

      }

      .react-select__single-value {
        color: hsl(0,0%,20%);
        margin-left: 2px;
        margin-right: 2px;
        max-width: calc(100% - 8px);
        overflow: hidden;
        position: absolute;
        text-overflow: ellipsis;
        white-space: nowrap;
        top: 50%;
        transform: translateY(-50%);
        box-sizing: border-box;
      }

      .react-select__input {
        margin: 2px;
        padding-bottom: 2px;
        padding-top: 2px;
        visibility: visible;
        color: hsl(0,0%,20%);
        box-sizing: border-box;
      }
    }

    .react-select__indicators {
      align-items: center;
      align-self: stretch;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      flex-shrink: 0;
      box-sizing: border-box;

      .react-select__indicator-separator {
        align-self: stretch;
        background-color: hsl(0,0%,80%);
        margin-bottom: 8px;
        margin-top: 8px;
        width: 1px;
        box-sizing: border-box;
      }

      .react-select__indicator .react-select__dropdown-indicator {
        color: hsl(0,0%,80%);
        display: flex;
        padding: 8px;
        -webkit-transition: color 150ms;
        transition: color 150ms;
        box-sizing: border-box;
      }
    }
  }

  .react-select__control .react-select__control--is-focused .react-select__control--menu-is-open {

  }

  .react-select__menu {
    z-index: 2000;
    .react-select__menu-list {
      min-width: 20em;
      background: white;

      .react-select__option {

        &:hover {
          @include gradient-bg($dropdown-link-hover-bg);
        }
      }
    }
  }

  .react-select__multi-value {
    background-color: rgb(230, 230, 230);
    border-radius: 2px;
    display: inline-block;
    padding: 1px 2px 1px 4px;
    margin-left: 2px;
    margin-right: 2px;
    min-width: 0px;

    .react-select__multi-value__label {
      display: inline-block;
    }

    .react-select__multi-value__remove {
      display: inline-block;

      &:hover {
        background-color: rgb(200, 200, 200);
      }
    }
  }
}

.input-group-prepend.react-select {
  .react-select__control {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.is-invalid .react-select .react-select__control {
  border-color: $form-feedback-invalid-color;
}

.react-select.country-select .react-select__value-container {
  min-width: 6em;

}
