

.status.status-small {

  &.statusDone:before {
    content: url(/resources/img/status-finished.png);
    width: 13px;
    height: 13px;
    margin-right: 0.5em;
  }

  &.statusInProgress:before {
    content: url(/resources/img/status-in-progress.png);
    width: 13px;
    height: 13px;
    margin-right: 0.5em;
  }

  &.statusRejected:before {
    content: url(/resources/img/status-rejected.png);
    width: 13px;
    height: 13px;
    margin-right: 0.5em;
  }

  //&.statusOrdered:before {
  //  width: 13px;
  //  height: 13px;
  //}
}

.status.status-default {
  &.statusInProgress:after {
    content: url(/resources/img/status-in-progress-big.png);
    width: 22px;
    height: 22px;
    vertical-align: middle;
    font-size: 0;
    margin-left: 20px;
  }
}
