// Układ strony klienckiej

div#root {
  overflow-x: hidden;
}

#main-navigation {
  background-color: $menu-background;
  width: 260px;
  position: fixed;
  top: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 400ms ease;

  @include small-screen-down {
    transform: translate3d(-260px, 0, 0);
  }


}

div#main {
  min-height: 100vh;
  height: 100%;
  transition: transform .4s ease;
  padding: 0 0 0 260px;
  background-color: $main-background;

  @include small-screen-down {
    padding-left: 0;
  }

  > * {
    padding-left: $content-padding;
    padding-right: $content-padding;
    @include small-screen-down {
      padding-left: $content-padding-sm;
      padding-right: $content-padding-sm;
    }
  }
}

main {
}

footer {
  padding: 50px 0;
  font-size: $font-size-sm;
  font-family: Rubik,sans-serif;
  flex-direction: row;

  @include small-screen-down {
    flex-direction: column;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 1em;
      &:not(:last-child) {
        margin-right: 1em;
      }
    }
  }

  img {
    margin-right: 0.5em;
  }

  .contacts {
    @include small-screen-up {
      padding-left: 2em;
      width: 35em;
    }
    @include small-screen-down {
      max-width: 35em;
    }
  }
}

// Obsługa rozwijania menu
@include small-screen-down {
  body.menu-visible {
    #main-navigation {
      transform: translate3d(0, 0, 0);
    }

    div#main {
      transform: translate3d(260px, 0, 0);
    }
  }
}

a {
  font-weight: $font-weight-bold;
}

footer {
  a {
    font-weight: $font-weight-base;
  }
}

.alert {
  > .icon {
    float: left;
    width: 2em;
    margin-top: 1em;
  }

  .icon-size {
    display: inline-block;
    min-width: 3em;
  }

  > .content {
    min-height: 4em;

    h1, > *:first-child {
      font-size: $h4-font-size;
    }

    margin-left: 3em;
  }
}

.contact-info {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: Rubik,sans-serif;

  > div {
    display: inline-block;
    padding-left: 1em;
    padding-right: 1em;

    img {
      margin-right: 0.5em;
    }

    a {
      font-weight: $font-weight-base;
    }
  }
}

.font-size-lg {
  font-size: $font-size-lg;
}

.font-size-h1 {
  font-size: $h1-font-size;
}

.font-size-h2 {
  font-size: $h2-font-size;
}

.font-size-h3 {
  font-size: $h3-font-size;
}

.actions-title {
  display: flex;

  h1, h2, h3, h4, h5 {
    flex-grow: 1;
  }

  *:not(:first-child) {
    margin-left: 1em;
  }
}

.col-splitter {
  border-left: 1px solid $solitude;
}

h3.section {
  margin-top: 3em;
  margin-bottom: 1.5em;
}

h4.section {
  margin-top: 2.5em;
  margin-bottom: 1.25em;
}

#main.flex-view {
  display: flex;
  flex-direction: column;

  #content {
    flex-grow: 1;
  };
}

#content.screen-external {
  position: relative;

  iframe.external-page {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    border: 0;
    height: 100%;
    min-height: 100%;
  }
}

