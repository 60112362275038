// Konfiguracja dla biblioteki hamburgers

$hamburger-types: (
        collapse
);
$hamburger-padding-x           : 0;
$hamburger-padding-y           : 0;
$hamburger-layer-width         : 35px;
$hamburger-layer-height        : 5px;
$hamburger-layer-spacing       : 6px;
$hamburger-layer-color         : $body-color !default;;
$hamburger-layer-border-radius : 0;
$hamburger-hover-opacity       : 0.7;
@import "hamburgers/hamburgers";

button.hamburger {
  outline: none;
}