// Style dla komponentu wyboru praw

.privilege {
  .header {
    display: flex;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $secondary;

    .left {
      flex: 4;
    }
    .right {
      flex: 6;
    }
  }
  .record {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $secondary;

    .data {
      padding-bottom: 10px;
      display: flex;
      align-items: center;

      .left { flex: 4; }
      .right { flex: 6; }

      &.user-status {
        .left { flex: 7; margin-right: 1em; }
        .right { flex: 3; }
      }
    }
  }

  .record-data {
    padding-top: 10px;
    .row {

    }
  }
}