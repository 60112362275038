@import "../../theme/admin";

#admin-menu {
  div.collapse > div, div.collapsing > div {
    margin-left: 2em;
  }
}

table.lang-keys {
  td {
    word-break: break-all;
  }
  td:nth-child(1) { width: 10em; max-width: 10em }
  td:nth-child(2) { min-width: 10em; }
  td:nth-child(3) { min-width: 10em; }
}

div.notes-map {
  border: 2px solid red;
  border-radius: $border-radius;
  padding: 1rem;
  margin: 0.5rem 0;
  font-weight: bold;
}