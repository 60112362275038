span.hint {
  font-size: $font-size-sm;
}

button.hint-rb-corner {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
}

label {
  button.text-info {
    padding: 0 0.2rem 0 0.6rem;
    margin: 0;
    height: auto;
    border: none;
  }

}

.tooltip.hint-tooltip {
  > div.tooltip-inner {
    > p {
      margin-bottom: 0.2rem;
    }
  }
}

.input-group-append > .input-group-text.hint {
  padding: 0;
  > .btn.btn-link {
    line-height: 1;
    padding: 0.2rem 0.3rem;
  }
}
