// Definicja przycisków, przy użyciu Bootstrapa

// Importujemy Bootstrapowe przyciski bez definicji kolorów
$theme-colors-cpy: $theme-colors; // zapamiętujemy
$theme-colors: ();                // puste, aby nie wygenerowało definicji
@import "../bootstrap/buttons";
$theme-colors: $theme-colors-cpy; // przywracamy

// Zmodyfikowany kod definicji kolorów przycisków z Bootstrapa
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    $active: map-get($theme-colors-active, $color);
    @include button-variant($value, $value, $active, $active, $active, $active);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

// Dodatkowy rozmiar dla przycisków
.btn-xl {
  @include button-size(0.3rem,
          $btn-padding-x-lg, $h5-font-size,
          $btn-line-height-lg, 21px);
}

div.page-header, div.modal-footer {
  .btn {
    @include button-size(0.3rem,
            $btn-padding-x-lg, $h5-font-size,
            $btn-line-height-lg, 21px);
  }
}

// Dodatek do przycisku - wyświetlenie kółka z ikonką
button.btn-icon {
  padding-left: 0.5rem;
  display: flex;
  align-items: center;

  span.icon {
    background: rgb(255,137,0);
    background: linear-gradient(180deg, rgba(255,137,0,1) 0%, rgba(254,196,128,0.7595413165266106) 100%);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 20px;
    transition: .3s;

    margin-right: 0.7em;

    &.inline {
      display: inline-flex;
    }
  }

  &.btn-success span.icon {
    background: $success;
  }

  &.btn-success span.outline-round {
    color: $success;
    background: $white;
    border-radius: 50%;
    font-size: 2em;
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    margin-right: 0.3em;
    div {
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

button.btn {
  text-transform: uppercase;

  &.btn-link, &.btn-no-transform {
    text-transform: none;
  }
}

.btn.btn-circle {
  width: 3em;
  height: 3em;
  border-radius: 50%;
}

div.button-notification {
  background-color: theme-color-level("danger", $alert-bg-level);
  color: theme-color-level("danger", $alert-color-level);

  div.popover-body {
    color: theme-color-level("danger", $alert-color-level);
  }

}

a.link-disabled {
  cursor: not-allowed;
  color: $link-color-disabled;
}