
.progress-button{
  margin: 0 auto;
  height: 50px;
  min-width: 200px;
  font-size: $font-size-lg;
  font-weight: $font-weight-semi-bold;
  text-align: center;
  transition: .3s;

  .loader{
    width: 32px;
    height: 32px;
  }
  .loader:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 6px solid $white;
    border-color:  $white transparent  $white transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  &.loading{
    .loader{
      display: inline-block;
    }
  }
  &.done{
    min-width: 50px;
    border-radius: 50%;
    .done{
      display: block;
      width: 26px;
      font-size: 24px;
    }
  }
}