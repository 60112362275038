.icon.gradient-circle {
  display: inline-block;
  width: $font-size-base*2;
  height: $font-size-base*2;
  line-height: $font-size-base*2;
  text-align: center;
  font-size: $font-size-base;

  background: #e1f0f8;
  background: linear-gradient(180deg,#e1f0f8 0,#fbfcfd);
  border-radius: 50%!important;
  color: $steelBlue !important;
}

.icon.big {
  font-size: $font-size-base*1.5;
}


.rf-icon {
  display: block;
  width: 35px;
  margin: 0 10px 0 0;
  background-size: auto 100%;
  background-position: left top;
  &.rf-icon-summary {
    height: 25px;
    background-image: url('/resources/img/menu-icons/icon-summary.png');
  }
  &.rf-icon-vindications {
    height: 33px;
    background-image: url('/resources/img/menu-icons/icon-vindications.png');
  }
  &.rf-icon-observation {
    height: 32px;
    background-image: url('/resources/img/menu-icons/icon-observation.png');
  }
  &.rf-icon-services {
    height: 26px;
    background-image: url('/resources/img/menu-icons/icon-services.png');
  }
  &.rf-icon-debt-exchange {
    height: 28px;
    background-image: url('/resources/img/menu-icons/icon-debt-exchange.png');
  }
  &.rf-icon-difficult-debts {
    height: 28px;
    background-image: url('/resources/img/menu-icons/icon-difficult-debts.png');
  }
  &.rf-icon-knowledge {
    height: 32px;
    background-image: url('/resources/img/menu-icons/icon-knowledge.png');
  }
  &.rf-icon-invoice-monitoring {
    height: 35px;
    background-image: url('/resources/img/menu-icons/icon-invoice-monitoring.png');
  }

  &.rf-icon-start {
    background-size: cover !important;
    background-position: center center !important;
    height: 33px;

    background: url('/resources/img/menu-icons/icon-start.png');
  }
  &.rf-icon-order {
    background-size: cover !important;
    background-position: center center !important;
    height: 33px;
    background: url('/resources/img/menu-icons/icon-order.png');
  }
  &.rf-icon-users-admin {
    background-size: cover !important;
    background-position: center center !important;
    height: 33px;
    background: url('/resources/img/menu-icons/icon-users.png');
  }
  &.rf-icon-hard {
    background-size: cover !important;
    background-position: center center !important;
    height: 33px;
    background: url('/resources/img/menu-icons/icon-hard.png');
  }
  &.rf-icon-reports {
    background-size: cover !important;
    background-position: center center !important;
    height: 33px;
    background: url('/resources/img/menu-icons/icon-reports.png');
  }
  &.rf-admin-icon-invoice-monitoring {
    height: 33px;
    background-image: url('/resources/img/menu-icons/icon-reports.png');
  }
  &.rf-icon-text {
    background-size: cover !important;
    background-position: center center !important;
    height: 33px;
    background: url('/resources/img/menu-icons/icon-text.png');
  }
  &.rf-icon-settings {
    background-size: cover !important;
    background-position: center center !important;
    height: 33px;
    background: url('/resources/img/menu-icons/icon-settings.png');
  }
}


svg.svg-icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
}