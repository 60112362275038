.field-icon {
  float: right;
  margin-right: 0.7em;
  margin-top: -1.8rem;
  position: relative;
  z-index: 2;
}

label {
  font-weight: $font-weight-semi-bold;
}

div.custom-checkbox {
  .custom-control-label {
    font-weight: normal;

    &::after {
      width: 10px;
      height: 6px;
      border-bottom: 2px solid #608fbf;
      border-left: 2px solid #608fbf;
      transform: scale(0) rotate(-45deg);
      margin-left: 4px;
      margin-top: 5px;
      transition: .2s;
    }
  }

  .custom-control-label:before {
    background-color: white;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: scale(1) rotate(-45deg);
  }
}

div.custom-radio {
  .custom-control-label:before {
    transition: background-color 0.3s;
    background-color: white;
  }

  .custom-control-label:after {
    background-image: none !important;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $body-color;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: scale(1);
  }

}


.invalid-feedback {
  display: block; // Walidacje są JS i są dynamicznie dodawane
  color: white;
  background: $form-feedback-invalid-color;
  font-size: $font-size-sm;
  padding: 10px .4rem 5px;
  margin: -5px 0 0;
  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

input[type=checkbox] ~ div.invalid-feedback {
  background-color: transparent;
  color: $form-feedback-invalid-color;
  padding: 0;
  margin: 0;
  border: none;
}

// Skopiowane z Bootstrap _custom-fonts.scss - fragmenty tylko związane z rozmiarem - bez kolorów i innych
$custom-control-indicator-size: 3rem;
$custom-switch-width:                           $custom-control-indicator-size * 1.75;
$custom-switch-indicator-border-radius:         $custom-control-indicator-size / 2;
$custom-switch-indicator-size:                  subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4);
$custom-control-gutter:                 2rem;

.form-control-xl {
  &.custom-switch {
    padding-left: $custom-switch-width + $custom-control-gutter;

    .custom-control-label {
      &::before {
        top: ($h1-font-size*1.2 - $custom-control-indicator-size) / 2;
        left: -($custom-switch-width + $custom-control-gutter);
        width: $custom-switch-width;
        height: $custom-control-indicator-size;
        // stylelint-disable-next-line property-blacklist
        border-radius: $custom-switch-indicator-border-radius;
      }

      &::after {
        top: add(($h1-font-size*1.2 - $custom-control-indicator-size) / 2, $custom-control-indicator-border-width * 2);
        left: add(-($custom-switch-width + $custom-control-gutter), $custom-control-indicator-border-width * 2);
        width: $custom-switch-indicator-size;
        height: $custom-switch-indicator-size;
        // stylelint-disable-next-line property-blacklist
        border-radius: $custom-switch-indicator-border-radius;
      }
    }
  }
  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      transform: translateX($custom-switch-width - $custom-control-indicator-size);
    }
  }
}

input.static:read-only {
  background-color: white;
}

div.form-text-length-info {
  font-size: $font-size-sm;
  color: $info;
  display: block;
  text-align: right;
  width: 100%;
  margin-top: 2px;

  &.length-invalid {
    color: $form-feedback-invalid-color;
  }
}