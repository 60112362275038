div.preview-email {
  iframe {
    width: 100%;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    background: white;
    height: 45rem;
    max-height: calc( 100vh - 25rem );
  }
}

div.preview-sms {
  iframe {
    width: 100%;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    height: 9rem;
    max-height: calc( 100vh - 25rem );
  }
}