// Rozszerzenie do Dropdown, które powoduje, że menu dolne jest złączone z przyciskiem

.dropdown.dropdown-drawer {
  &.show button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:after {
      transform: rotate(180deg);
    }
  }

  .dropdown-menu {
    border-radius: 0 0 $btn-border-radius-lg $btn-border-radius-lg;
    right: 0 !important;
    top: -2px !important;
    border: 0;

    .dropdown-item {
      font-size: $font-size-base;
      padding: 5px 10px;
    }
  }
}

@each $color, $value in $theme-colors {
  $active: map-get($theme-colors-active, $color);
  .dropdown.dropdown-#{$color} {
    .dropdown-menu {
      background: $active;

      .dropdown-item {
        &:hover {
          background: $value;
        }

        color: color-yiq($value);
      }
    }
  }
}