@font-face {
  font-family: 'icomoon';
  src:  url('/resources/webfonts/icomoon.eot?wdv7ha');
  src:  url('/resources/webfonts/icomoon.eot?wdv7ha#iefix') format('embedded-opentype'),
    url('/resources/webfonts/icomoon.ttf?wdv7ha') format('truetype'),
    url('/resources/webfonts/icomoon.woff?wdv7ha') format('woff'),
    url('/resources/webfonts/icomoon.svg?wdv7ha#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-filter:before {
  content: "\e90e";
}
.icon-user:before {
  content: "\e90b";
}
.icon-edit:before {
  content: "\e909";
}
.icon-document-file-pdf:before {
  content: "\e90c";
}
.icon-mail-envelope-closed:before {
  content: "\e908";
}
.icon-linegraph:before {
  content: "\e039";
}
.icon-happy:before {
  content: "\e05b";
}
.icon-log-out:before {
  content: "\e907";
}
.icon-add-outline:before {
  content: "\e904";
}
.icon-group:before {
  content: "\e90d";
}
.icon-users:before {
  content: "\e90d";
}
.icon-cog:before {
  content: "\e905";
}
.icon-gear:before {
  content: "\e905";
}
.icon-user-circle:before {
  content: "\e906";
}
.icon-angle-up:before {
  content: "\e903";
}
.icon-angle-right:before {
  content: "\e900";
}
.icon-angle-left:before {
  content: "\e901";
}
.icon-angle-down:before {
  content: "\e902";
}
.icon-office:before {
  content: "\e90a";
}
.icon-file-empty:before {
  content: "\e924";
}
.icon-location:before {
  content: "\e947";
}
.icon-calendar:before {
  content: "\e953";
}
.icon-display:before {
  content: "\e956";
}
.icon-mobile:before {
  content: "\e958";
}
.icon-floppy-disk:before {
  content: "\e962";
}
.icon-user-plus:before {
  content: "\e973";
}
.icon-search:before {
  content: "\e986";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-hammer2:before {
  content: "\e9a8";
}
.icon-list2:before {
  content: "\e9bb";
}
.icon-download2:before {
  content: "\e9c5";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-checkmark:before {
  content: "\ea10";
}
