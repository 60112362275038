
div.progress.money-bar {
  height: 4px;
}

div.progress-bar-valued {
  margin: 0.2em 0;

  div.progress-bar-values {
    width: 100%;
    position: relative;
    height: 1em;
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
    display: flex;

    span.max {
      position: absolute;
      right: 0;
    }

    span.min {
      position: absolute;
      left: 0;
    }

    span.now {
    }
  }
}

div.progress-bar-small {
  display: flex;
  align-items: center;
  div.progress {
    flex-grow: 1;
  }
  span {
    min-width: 3em;
    text-align: right;
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
  }
}

div.progress-bar-labeled {
  div.progress-bar-label {
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;

    display: flex;
    label {
      flex-grow: 1;
    }
  }
}