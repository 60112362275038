// Plik ze wspólnymi zmiennymi dla obu stron - klienckiej i administracyjnej.

/**********************************************
Font
**********************************************/

$poppinsFontFamily: 'Poppins', sans-serif ;
$rubikFontFamily: 'Rubik', sans-serif;


/**********************************************
COLORS
**********************************************/

$congressBlue: #034280;
$bayOfMany: #254D85;
$hippieBlue: #618FBF;
$white: #FFFFFF;
$dodgerBlue: #23B7FB;
$danube: #82ADD9;
$blackSqueeze: #E8F1F7;
$blackSqueezeLight: #F2F7FA;
$steelBlue: #608FBF;
$pizazz: #FF8900;
$clementine: #F07000;
$weak: #E31010;
$normal: #f1e374;
$strong: #279927;
$twilightBlue: #FEFFFF;
$regentStBlue: #A1C3E5;
$regentStBlue-2: #A5C2DE;
$regentStBlue-3: #ACCEE3;
$rockBlue: #A3B8CC;
$crimson: #E31010;
$lima: #32C924;
$coralRed: #FF4147;
$linkWater: #DDE9F3;
$poloBlue: #8EABC8;
$poloBlue-2: #9CB9D7;
$blueZodiac: #0C2446;
$solitude: #E6F2FF;
$mercury: #E5E5E5;
$polar: #E2F2F8;
$medium: #FC8924;
$none: #E31117;
$stTropaz: #266099;
$pigeonPost: #B3C6D9;
$catskillWhite: #FDFEFE;
$portafino: #FFFCB7;
$botticelli: #D2DDE9;
$havelockBlue: #5E9EDE;

$primary:       $pizazz !default;
$secondary:     $congressBlue !default;
$success:       $lima !default;
$danger:        $crimson !default;
$light:         $regentStBlue-2 !default;

/**********************************************
Weight
**********************************************/

$font-weight-medium:            500 !default;
$font-weight-semi-bold:            600 !default;

/**********************************************
Overwrite boostrap varaibles
**********************************************/

$font-family-sans-serif: $poppinsFontFamily;
$body-color: $congressBlue;

$link-color: $congressBlue;
$link-color-disabled: lighten($congressBlue, 30%);
$link-decoration: none;
$link-hover-color: darken($congressBlue, 15%) !default;
$link-hover-decoration: none;

$container-max-widths: (
        sm: 640px,
        md: 820px,
        lg: 1100px,
        xl: 1366px
);

$grid-breakpoints: (
        xs: 0,
        sm: 700px,
        md: 840px,
        lg: 1130px,
        xl: 1400px
);


$btn-border-width:      2px;
$btn-border-radius:     1.25rem;
$btn-border-radius-lg:  6.25px;
$btn-border-radius-sm:  0.9375rem;
$btn-font-size:         0.875rem;
$btn-font-size-sm:      0.625rem;
$btn-padding-x-sm: 1.0625rem;

$btn-border-radius-md:        15px;
$btn-padding-y-md:            0.375rem !default;
$btn-padding-x-md:            .625rem !default;
$btn-font-size-md:            12px;
$btn-line-height-md:          1.5;


//$btn-padding-y-lg:  ;
//$btn-padding-x-lg:  ;
$btn-font-size-lg:            14px;
//$btn-line-height-lg:  ;
$btn-border-radius-lg:        15px;

$select-border-radius: 0.625rem !default;
$select-outline-font-weight: $font-weight-semi-bold !default;
$select-font-size: 0.8125rem !default;
$select-min-height: 2.8125rem !default;

$modal-inner-padding:               1rem;
$modal-content-bg:                  $blackSqueezeLight;
$modal-content-border-color:        transparent;
$modal-content-border-width:        0;
$modal-content-border-radius:       24px;

$modal-backdrop-bg:                 #002040;
$modal-backdrop-opacity:            .75;

$modal-md:                          100%;
$modal-sm:                          300px !default;


$enable-responsive-font-sizes: true;

$btn-focus-box-shadow: none;
$input-btn-focus-width: 0;



$border-radius:               .55rem !default;
$border-radius-lg:            .7rem !default;
$border-radius-sm:            .4rem !default;

$input-color:                           $blueZodiac;
$input-border-color:                    $steelBlue;

$form-grid-gutter-width:                10px;
$form-group-margin-bottom:              1.5rem;
$input-group-addon-bg:                  $white;
$input-group-addon-border-color:        $steelBlue;

$input-btn-font-size:         13px;

$input-btn-focus-width:       0;
$input-btn-focus-color:       $steelBlue;
$input-btn-focus-box-shadow:  none;

$input-focus-bg:                        none;
$input-focus-border-color:              $steelBlue;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     0;
$input-focus-box-shadow:                none;

/** Custom checkbox */

$custom-control-indicator-size: 1.5rem;
$custom-checkbox-indicator-icon-checked: none;
$custom-control-indicator-checked-bg: white;
$custom-control-indicator-active-bg: white;

//$custom-control-indicator: white;

$input-font-family: Rubik, sans-serif;
$input-font-size: 1.07rem;
$input-color: $secondary;
$input-padding-x: 0.5rem;
$input-padding-y: 0.6rem;

$alert-border-radius:         20px;

$yiq-contrasted-threshold:  165;    // Aby na przyciskach był biały kolor
$primary-active: $clementine; // pomocniczy kolor

// Rozmiary czcionek
html {
  // od tego rozmiar zależne są wszystkie rozmiary czcionki jest to baza dla 1 rem
  font-size: 12px;
}
$font-size-base: 1rem;
$font-size-lg: 1.17rem;
$font-size-sm: 0.92rem;
$h1-font-size: 3.334rem;
$h2-font-size: 2rem;
$h3-font-size: 1.666rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.3333rem;

$headings-font-weight: $font-weight-semi-bold;
//$form-la: $font-weight-semi-bold;
$btn-font-weight: $font-weight-semi-bold;

$btn-font-size: $font-size-base;
$btn-font-size-sm: $font-size-sm;
$btn-font-size-lg: $font-size-lg;
$info: $hippieBlue;

$input-btn-font-size:         $font-size-lg;


// Okno modalne - w pliku _modal.scss jest jeszcze kilka zmian ponad tą konfigurację
$modal-header-padding: 4rem;
$modal-inner-padding: 4rem;

$custom-checkbox-indicator-border-radius: 0;



$breadcrumb-font-size:              $font-size-sm;
$breadcrumb-padding-y:              1.375rem;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding:           .5rem;
$breadcrumb-margin-bottom:         0;
$breadcrumb-bg:                     none;
$breadcrumb-divider-color:          $regentStBlue;
$breadcrumb-active-color:          $congressBlue;
$breadcrumb-divider:                quote("");

$breadcrumb-border-radius:          0;
$breadcrumb-separator-color:        $secondary;

$table-bg: $white;
$table-color: $congressBlue;
$table-border-color: #dce5ed;

$content-padding: 45px;
$content-padding-sm: 15px;

$nav-tabs-border-radius: 15px;
$nav-link-padding-y: 1rem;
$nav-link-padding-x: 2rem;
$nav-tabs-link-active-color: $congressBlue;

$custom-control-indicator-bg: $secondary;

// Card - panele
$card-border-width: 0;
$card-border-radius: 10px;
$card-bg: $white;
$card-spacer-x: 20px;
$card-spacer-y: 20px;