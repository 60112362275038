
$datepicker__text-color: $body-color;
$datepicker__header-color: $body-color;
$datepicker__font-family: $font-family-sans-serif;
$datepicker__font-size: $font-size-base;
$datepicker__selected-color: $body-color;

@import "../node_modules/react-datepicker/src/stylesheets/datepicker";

div.react-datepicker {
  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
  }

  .react-datepicker__month {
    font-weight: 400;
  }
}

.react-datepicker-popper {
  z-index: 10;
}