
$table-head-bg: #e6f0f7;
$table-border-radius: 15px;

table.table {
  background-color: $main-background;

  tr.error-row {
    background-color: lighten($danger, 35);
  }

  td, th {
    padding: 10px 10px;
    vertical-align: middle;
  }

  td.sorted {
    background-color: $blackSqueeze;
  }

  th.sorted {
    background-color: #e2eef5;
  }

  th.sorting {
    position: relative;

    &:after {
      position: absolute;
      right: 0;
      display: inline-block;
      visibility: hidden;
      content: "";
      border-radius: 2px;
      border: solid $regentStBlue;
      border-width: 0 3px 3px 0;
      padding: 3px;
      transform: rotate(45deg);
      top: 50%;
      bottom: unset;
      right: 10px;
      margin-top: -6px;
    }

    &.sorted:after {
      visibility: visible;
    }

    &.sorted-asc:after {
      transform: rotate(225deg);
    }

    &.sorted-desc:after {

    }
  }

  td:first-child, th:first-child {
    padding-left: 20px;
  }

  td:last-child, th:last-child {
    padding-right: 20px;
  }

  thead {
    background-color: $table-head-bg;
    tr:first-child {

      th {
        border-top: none;
        border-bottom: 1px;
      }

      th:first-child {
        border-top-left-radius: $table-border-radius;
      }
      th:last-child {
        border-top-right-radius: $table-border-radius;
      }
    }
  }
  tbody {
    background-color: $table-bg;

    tr.header-bg {
      background-color: $table-head-bg;
    }
  }
}

div.datatable-content {
  overflow-x: auto;
}

div.table-footer, table.round-bottom {
  background-color: $table-bg;
  border-bottom-left-radius: $table-border-radius;
  border-bottom-right-radius: $table-border-radius;
}

.tab-content {
  table.table {
    background-color: $white;
  }
}

div.datatable {
  @if $mode-client {
    box-shadow: 0 0 10px 0 rgba(3,66,128,.1);
    border-radius: $table-border-radius;

    div.datatable-content {
      border-top-left-radius: $table-border-radius;
      border-top-right-radius: $table-border-radius;
    }
  }

  &.footer-header-bg div.table-footer {
    background-color: $table-head-bg;
  }

  table {
    margin: 0;
    thead {
      tr.header {
        th {
          text-transform: uppercase;
        }
      }

      tr.filters {
        th {
          padding: 5px 5px;
        }
      }
    }

    tbody {
      tr {
        td {
          h1 {
            font-size: $font-size-lg;
          }
          small {
            font-size: $font-size-sm;
          }
        }
      }
    }

    div.lr-sum {
      display: flex;
      > *:first-child {
        flex-grow: 1;
      }
    }

    tr.summary {
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
    }
  }

  div.table-footer {
    padding: 1rem 0;
    p {
      padding: 0;
      margin: 0;
      text-align: center;
    }
  }

}

.date-filter {
  display: flex;
  align-items: center;

  span {
    margin-right: 0.3em;
    margin-left: 0.3em;
  }
  span:first-child {
    margin-left: 0;
  }

  .form-control {
    width: 6.5em;
  }
}

.range-filter {
  display: flex;
  align-items: center;
  span {
    margin-right: 0.3em;
    margin-left: 0.3em;
  }
  span:first-child {
    margin-left: 0;
  }

  .form-control {
    width: 6.5em;
  }

}

table.table.no-bg {
  background-color: transparent;

  thead, tbody {
    background-color: transparent;
  }
}

div.load-more {
  display: block;
  text-align: center;
  > p {
    margin-top: 0.5em !important;
  }
}