

div.card {
  div.card {
    background-color: $main-background;
  }
}

div.tab-content {
  div.card {
    background-color: $main-background;
  }
}

div.card.data {
  @if ($mode=="admin") {
    color: $congressBlue;
  } @else {
    color: $secondary;
  }
  font-weight: bold;

  div.sep-border {
    border-right: 1px solid $steelBlue;
  }

  div.icon {
    font-size: 2.8rem;
  }


  label {
    display: block;
    text-transform: uppercase;
    color: $steelBlue;

    &.missing-value {
      color: $danger;
    }
  }

  &.card-secondary {
    background-color: $congressBlue;
    color: $white;

    label {

    }
  }


  div.alert {
    width: 100%;
    background-color: $blackSqueezeLight;
  }
}


.card.properties {
  .card-title {
    padding: 10px 0;
    margin: 0 0 10px;
    @if $mode-admin {
      border-bottom: 1px solid $steelBlue;
    }

    a {
      float: right;
      color: $dodgerBlue;
      font-size: $font-size-base;
    }

  }

  .card-body {
    > div {
      margin-bottom: 1rem;
      font-size: $font-size-lg;

      @if $mode-client {
        &:not(:last-child) {
          padding-bottom: 1rem;
          border-bottom: 1px solid $solitude;
        }
      }


      .label {
        font-weight: $font-weight-bold;
      }
      .value {
        font-size: $font-size-base;
        @if $mode-admin {
          color: $danube;
        } @else {
          color: $secondary;
        }
      }
    }
  }
}

.card.blue {
  background: url(/resources/img/bg_auth.jpg) no-repeat;
  background-position: 50%;
  background-size: cover;

  color: $white;
  font-weight: $font-weight-bold;

  h3.occupy {
    min-height: 2.2em;
  }

  div.sep-border {
    border-right: 1px solid hsla(0,0%,100%,.3);
  }

  div.sep-border-white {
    border-right: 1px solid white;
  }


  h1, h2, h3, h4, h5 {
    color: $dodgerBlue;
  }

  label {
    color: $poloBlue-2;
    text-transform: uppercase;
    display: block;
  }

  div.icon {
    font-size: 2.8rem;
  }

  button, a {
    color: $white;
    font-weight: $font-weight-bold;

    &:hover {
      color: $dodgerBlue;
    }
  }
}

.card.color1 {
  background: $blackSqueeze;
}

.card.color2 {
  border: 1px solid $poloBlue;
  background: $blackSqueezeLight;
}


#content > div.tab-content {
  div.card {
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(3,66,128,.1);
    margin-bottom: 30px;

    &.color1 {
      background: $blackSqueeze;
    }
    &.color2 {
      border: 1px solid $poloBlue;
      background: $blackSqueezeLight;
    }
  }
}

card.data {
  label {
    text-transform: uppercase;
    font-size: $font-size-sm;
    color: $poloBlue;
    display: block;
  }

}

card.shadow {
  box-shadow: 0 0 10px 0 rgba(3,66,128,.1);
}