.service-select {
  cursor: pointer;
  color: white;

  background-color: $lima;
  transition: background-color 0.2s linear;

  display: flex;
  align-items: center;
  padding: 15px 30px;

  &:hover {
    background-color: #2aa91e;
  }

  > div {
    flex-grow: 1;
  }

  h1 {
    font-size: $font-size-lg;
  }

  > span {
    font-size: $font-size-lg*6;
  }
}

.modal {
  .alert.alert-info {
    background: white;
  }
}

.alert.single-line {
  .content {
    font-size: $h3-font-size;
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;
    min-height: 2.5em;
  }
}

.alert.alert-white {
  background: white;
}