// Dodatkowe style na potrzeby wyświetlania rzeczy wygenerowanych w Quill

.ql-indent-1 {
  margin-left: 40px;
}

.ql-indent-2 {
  margin-left: 80px;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}