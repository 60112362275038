// Lewe oraz górne menu

#main-navigation {
  .logo {
    height: 110px;
    padding: 35px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $menu-logo-background;

    img {
      //width: 100%;  -- na firefox nie działa
      width: 220px;
      height: auto;
      padding: 0 1em;
    }
  }

  .add-service {
    width: calc(100% - 30px);
    margin: 30px auto;

    .btn-primary {
      padding: 4px 6px;

      &:after {
        @include icomoon();
        right: 10px;
        border: 0;
        //transition: transform 0.1s;
      }
    }
  }

  .accordion {

    button, a {
      color: $menu-color;
      background: $menu-background;
      &:hover, &.expanded {
        color: $menu-color-hover;
      }
    }

    > button {
      &:after {
        font-family: icomoon !important;

        position: absolute;
        right: 1.2em;
        speak: none;
        font-style: normal;
        font-weight: 400;
        content: "\E902";
        transform: rotate(0deg);
      }

      &.expanded:after {
        transform: rotate(180deg);
      }
    }

    > button, >a {
      display: flex;
      align-items: center;
      position: relative;
      padding: 15px;
      outline: 0;
      border: 0;
      width: 100%;
      font-size: $font-size-lg;
      font-weight: 600;

      &:before{
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 4px;
      }

      &:hover, &.expanded {
        &:before {
          background-color: $menu-color;
        }
      }
    }

    > div {
      position: relative;
      font-size: $font-size-base;
      font-weight: 600;

      a {
        padding: 15px 15px 15px 60px;
        display: block;

        &:hover, &.active {
          background-color: $menu-background-hover;
        }
      }

      &:before{
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 4px;
        background-color: $menu-color;
      }
    }
  }
}

#top-navigation {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: stretch;
  background-color: $top-menu-background;

  .menu-toggle {
    align-self: center;

    button {
      margin: 4px;
      outline: 0;
      border: 0;
    }
  }

  .technical-message {
    flex-grow: 1;
    align-self: center;
    color: $secondary;
  }

  .logo {
    flex-grow: 1;
    text-align: center;
    align-self: center;
    padding: 5px 5px;

    img {
      height: auto;
      max-height: 2.8em;
    }
  }

  div.help-icon {
    display: flex;
    align-items: center;
    align-content: center;
  }

  button.btn-icon, a.btn-icon {
    font-size: $font-size-base*1.8;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  /** Znacznik powiadomień w górnym menu */
  .notifications {
    .dropdown {
      height: 100%;

      .dropdown-menu.user-notifications {
        border-radius: 0;

        @media (min-width: 26em) {
          min-width: calc(30em - 3px);
        }
        @media (max-width: 26em) {
          min-width: calc(100vw - 30px);
        }
      }

      button {
        background: $blackSqueeze;
        border: 0;
        border-radius: 0;
        width: 100%;
        height: 100%;
        color: $body-color;
        transition: color 0s;
        font-size: 2rem;
        padding-right: 0;
        padding-left: 1.3rem;

        .badge {
          left: -10px;
          top: 9px;
          font-size: 0.8rem;
          padding: 0;
          line-height: 1.3rem;
          min-width: 1.3rem;
          min-height: 1.3rem;
        }

        &:after {
          border: 0;
          display: none;
        }
      }

      &.show {
        button {
          background: white;
        }
      }
    }
  }
}

.user-menu {
  .dropdown-menu.user-companies {
    top: -3px !important;
    left: 1px !important;
    right: 1px !important;
    padding: 0;
    border-radius: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);

    .list-group {
      //font-size: 1rem;

      span.disabled {
        color: $rockBlue;
      }

      .list-group-item.actions {
        font-size: $font-size-sm;


        a, button {
          color: $rockBlue;

          span {
            font-size: 1.34rem;
          }

          &:hover {
            color: $dodgerBlue;
          }
        }
      }
    }

    a, button {
      display: flex;

      span {
        margin-right: 5px;
        font-size: 1rem;
      }

      &:hover {
        color: $dodgerBlue;
      }
    }
  }

  .dropdown {
    width: 25em;

    button {
      width: 100%;
      background: $blackSqueeze;
      border-radius: 0;
      border: 1px solid $blackSqueeze !important;
      display: flex;
      color: $rockBlue;
      transition: color 0s;
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;

      &:after {
        @include icomoon($size: 30px);
        position: absolute;
        top: calc(50% - 0.5em);
        right: 10px;
        border: 0;
        //transition: transform 0.1s;
      }

      div.info {
        text-align: left;

        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 21em;
        }

        .name {
          font-size: $font-size-lg;
          color: $rockBlue;
        }

        .company {
          font-size: $font-size-base;
          color: $body-color;
        }
      }
    }

    &.show {
      button {
        background: white;
      }
    }
  }
}

#top-navigation {
  .admin-remote-help {
    margin-left: 1em;
    input {
      width: 7.5em;
    }
  }
}

#main-navigation {
  .user-menu {
    .dropdown {
      width: 100%;

      button {
        background: white;
        border: none !important;
      }
    }
  }

  .badge {
    margin-left: 0.5em;
    min-width: 1.5em;
  }
}
