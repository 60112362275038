// Style dla części związanej z formularzem logowania.
// Plik jest tu, bo są one wspólne dla części administracyjnej oraz klienckiej.

html, body {
  height: 100%;
}


.auth {
  height: 100%;
  min-height: 100%;

  #root {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    main {
      background: url(/resources/img/bg_auth.jpg) no-repeat 50%;
      background-size: cover;
      flex: 1;
      padding: 50px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .container {
        max-width: 800px;
        width: 100%;

        header {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          color: white;
          font-size: $font-size-lg;
          padding-bottom: 1rem;

          a {
            color: $dodgerBlue;
          }

          &:first-child {

          }

          &:last-child {

          }

          .logos {
            span {
              margin-left: 1em;
              margin-right: 1em;
              font-size: $h3-font-size;
            }
          }

          .auth-logo, .euleo-logo {
            height: auto;
            width: 100%;
            max-width: 15em;
          }


        ;
        }

        .auth-info {
          text-align: center;
          font-size: $h4-font-size;

          a {
            color: white;
          }
        }
      }

      .modal-content {
        background-color: white;
      }
    }
  }

  .change-language {
    font-weight: $font-weight-bold;
  }

  .register {
    font-weight: $font-weight-bold;
    a {
      color: $dodgerBlue;

      &:hover {
        text-decoration: underline;
      }
    }
  }

}